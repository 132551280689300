export type BackendVariableKind =
    | 'uin'
    | 'gtin'
    | 'accounting_method_type'
    | 'weight'
    | 'gradation_classification_name'
    | 'gradation_name'
    | 'product_name'
    | 'icing_percentage'
    | 'fishing_area_name'
    | 'fishing_permit_name'
    | 'manufacturing_company_name'
    | 'exporting_company_name'
    | 'buying_company_name'
    | 'vessel_name'
    | 'production_date'
    | 'production_date_set_manually'
    | 'shelf_life_in_months'
    | 'expiration_date'
    | 'shift'
    | 'master_full_name'
    | 'number_of_boxes';

export type BackendPropLiterals = BackendVariableKind | 'barcode';

export type BackendVariableProp = {
    kind: BackendVariableKind;
    name: string;
    schematicView: string;
    schematicPreview: string;
};

export type BackendBarcodeProp = {
    kind: 'barcode';
    name: string;
};

export type BackendProp = BackendVariableProp | BackendBarcodeProp;

export type FontSize = 8 | 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 36 | 48 | 72;
export type Size = { width: number; height: number };

// not a type, I know
// but I didn't found a better place for this (maybe in EditorControls.tsx)
export const fontSizeList: FontSize[] = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
