import { randomInt } from 'fp-ts/Random';
import {
    EditorNodeBackendPropMetadata,
    EditorNodeImageMetadata,
    EditorNodeTextMetadata,
} from '../../components/EditorNodes/interface/node.interface';
import { approximateTextSize } from '../../utils/strings';
import { BackendBarcodeProp, BackendVariableProp } from '../../types/editor';

const image200x200 =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIBAMAAABfdrOtAAAAG1BMVEXMzMwAAACysrJ/f39MTEwZGRlmZmYzMzOZmZm1uGWgAAACuElEQVR4nO2VS0/bQBSFB9uJvexA0mRpKkWwbBRasbRbFbGMq0TdBkW0Wyz4AQl9/O7Oe64djyFSpW7OJ2HMcM6dOTPXNmMAAAAAAAAAAAAAAAAAAAAAAAAAAAD8Z965uyjvGOygU0cGD7iq+PiTvv3F+Uors9INHhJ/6dJ58yEfxjeLH/y9vE3GnxflWzVarhZXfBOYo1ptP9ZnLR0xH/K0E5ffU3lbFyJzLS3pSFwuApb0Vkaodk2dN3eQy0s0FpehdLDBqbjcFfK2yjsdkbqeLxs6Yg5SinUNlqrERFzWus5DjyOZNnTEHGS+ET/qYFids1hveNq3rlhsEtF5c5i9mKTUAiFPdHVTw+yP2VlLJiYhOm/un0RnZ+eFyc6ikR6ZbXStaXsSovPmMGIFtuTgwUm/6V+JbrN544jk8r2OmMOIvcxMx6ZvVC41av5byr/j5qHKg/A6Yg4iuyIzJ5CISczOloxEaQZRLeV1xBxE9mPsdfPWJDJKK4hqeq+LXzHJviCTnHpz7qO0gmTyELyOmENE67x/ElZuW0FmX4+dJJXN2XMmYpC32qbesSPPpJTinu4SMXnRcCSq6DHdpZ8D1+pL1/9rJ5k/NV/JqqmJjphDQfSCzK6fFOzE7M3IBZlYEV0W1XlzIIipZTZHvCtN6NitXrRWQqOUuhbReXNvENsrpXsLu1ZRzwiJYickOm/uJLVL1J8P+fCbz4L7nqhnhESxH0Ci8+Yuotq+nYeqimrnO1VFtSlzby0X5eLMmr2OmDuY+fFKTqe2eyDL2LOyiRKjjKqddRCdN3cEqW6+S6RkNrm+vB/p0cfnP5V1xOa3iTwbKce2qfPmQzKuUYu953yiKw0rzh+7HWyvHZOWzptf4PI6twl/Pr/GQHXeDAAAAAAAAAAAAAAAAAAAAAAAAAAA/i1/AaVAc3FQLwfoAAAAAElFTkSuQmCC';

export const backendBarcodeProp: BackendBarcodeProp = {
    kind: 'barcode',
    name: 'Штрихкод',
};

export const backendNumberOfBoxesProp: BackendVariableProp = {
    kind: 'number_of_boxes',
    name: 'Количество коробок',
    schematicView: '{Количество коробок}',
    schematicPreview: '2',
};

export const backendVariableProps: BackendVariableProp[] = [
    {
        kind: 'uin',
        name: 'УИН выпущенной коробки',
        schematicView: '{УИН выпущенной коробки}',
        schematicPreview: '274531630571309',
    },
    {
        kind: 'weight',
        name: 'Масса нетто',
        schematicView: '{Масса нетто}',
        schematicPreview: '13.61',
    },
    {
        kind: 'gradation_name',
        name: 'Градация',
        schematicView: '{Градация}',
        schematicPreview: '12-14 oz',
    },
    {
        kind: 'product_name',
        name: 'Название продукции',
        schematicView: '{Название продукции}',
        schematicPreview:
            'Product name/产品名称：Абдомен в панцире краба камчатского варено-мороженый воздушной заморозки/RED KING CRAB ABDOMEN (TAIL IN SHELL) BOILED FROZEN AIR BLAST/煮冻红帝王蟹的腹部，空气爆炸冻结/Scientific Name/学名：PARALITHODES CAMTSCHATICUS',
    },
    {
        kind: 'icing_percentage',
        name: 'Глазурь',
        schematicView: '{Глазурь}',
        schematicPreview: '10',
    },
    {
        kind: 'fishing_area_name',
        name: 'Район промысла',
        schematicView: '{Район промысла}',
        schematicPreview: 'Западно-беринговоморская подзона 6101',
    },
    {
        kind: 'production_date',
        name: 'Дата изготовления',
        schematicView: '{Дата изготовления}',
        schematicPreview: '2021-02-31',
    },
    {
        kind: 'shelf_life_in_months',
        name: 'Срок годности',
        schematicView: '{Срок годности}',
        schematicPreview: '12',
    },
    {
        kind: 'expiration_date',
        name: 'Годен до',
        schematicView: '{Годен до}',
        schematicPreview: '2021-02-31',
    },
    {
        kind: 'manufacturing_company_name',
        name: 'Компания-изготовитель',
        schematicView: '{Компания-изготовитель}',
        schematicPreview: 'ООО "ПАСИФИК КРАБ" / LLC \'Pacific Crab\'',
    },
    {
        kind: 'exporting_company_name',
        name: 'Компания-экспортер',
        schematicView: '{Компания-экспортер}',
        schematicPreview: 'ООО "КАМЧАТКА КРАБ" / LLC \'Kamchatka Crab\'',
    },
    {
        kind: 'buying_company_name',
        name: 'Компания-покупатель',
        schematicView: '{Компания-покупатель}',
        schematicPreview: 'PACIFIC PRIME CRAB PTE LTD.',
    },
    {
        kind: 'vessel_name',
        name: 'Название судна',
        schematicView: '{Название судна}',
        schematicPreview: 'Обаятельный',
    },
    {
        kind: 'shift',
        name: 'Смена',
        schematicView: '{Смена}',
        schematicPreview: '1',
    },
    {
        kind: 'master_full_name',
        name: 'ФИО мастера',
        schematicView: '{ФИО мастера}',
        schematicPreview: 'Марковский К. А.',
    },
];

export const exampleImageMetadata: EditorNodeImageMetadata = {
    kind: 'image',
    size: { width: 200, height: 200 },
    position: { x: 731, y: 126 },
    content: { blob: image200x200 },
};

const longText =
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci amet aperiam architecto atque blanditiis, dicta eius minima minus nihil quas quibusdam quo reprehenderit saepe veniam veritatis voluptatem voluptates. Nobis, voluptates?';
export const exampleTextMetadata: EditorNodeTextMetadata = {
    kind: 'text',
    size: approximateTextSize(longText, 12),
    position: { x: 10, y: 10 },
    content: { text: longText, fontSize: 14 },
};

const getRandomPropIndex = randomInt(0, backendVariableProps.length - 1);
const randomProp = backendVariableProps[getRandomPropIndex()];
export const exampleBackendPropsMetadata: EditorNodeBackendPropMetadata = {
    kind: 'backendProp',
    size: approximateTextSize(randomProp.schematicView, 20),
    position: { x: 336, y: 170 },
    content: { fontSize: 20, prop: randomProp },
};

export const exampleBackendBarcodeMetadata: EditorNodeBackendPropMetadata = {
    kind: 'backendProp',
    size: { width: 831, height: 236 },
    position: { x: 245, y: 360 },
    content: { fontSize: 12, prop: backendBarcodeProp },
};
